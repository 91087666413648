import * as React from 'react';
import { MDXProvider } from '@mdx-js/react';
import {
  H1, H2, H3, H4, H5, H6,
} from '../components/typography/Heading';
import P from '../components/typography/Paragraph';

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: P,
};

const CustomMDXProvider = ({ children }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
);

export default CustomMDXProvider;
