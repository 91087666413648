// custom typefaces
import 'typeface-merriweather';
import 'typeface-montserrat';
import React, { useState, useEffect } from 'react';
import { silentAuth } from './src/utils/auth';
import CustomMDXProvider from './src/utils/CustomMDXProvider';

const SessionCheck = (props) => {
  const [loading, setLoading] = useState(true);

  const handleCheckSession = () => {
    setLoading(false);
  };

  useEffect(() => {
    silentAuth(handleCheckSession);
  }, []);

  return (
    loading === false && (
    <>{props.children}</>
    )
  );
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <CustomMDXProvider>
    <SessionCheck>
      {element}
    </SessionCheck>
  </CustomMDXProvider>
);
