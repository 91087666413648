import * as React from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  font-family: 'Merriweather','Georgia',serif;
  font-weight: 400;
  word-wrap: break-word;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin-bottom: 1.75rem;
`;

const P = ({ children, style = {}, className = '' }) => (
  <Paragraph className={className} style={style}>{children}</Paragraph>
);

export default P;
