import * as React from 'react';
import styled, { css } from 'styled-components';

const sharedStyle = css`
  margin: 3.5rem 0 1.75rem 0;
  font-family: 'Merriweather','Georgia', serif;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  line-height: 1.1;
`;

const StyledH1 = styled.h1.attrs(({ style }) => ({ style }))`
  ${sharedStyle};
  font-family: Montserrat,sans-serif;
  font-size: 3.95285rem;
  line-height: 4.375rem;
  margin-bottom: 2.625rem;
`;

const StyledH2 = styled.h2.attrs(({ style }) => ({ style }))`
  ${sharedStyle};
  font-size: 1.73286rem;
`;

const StyledH3 = styled.h3.attrs(({ style }) => ({ style }))`
  ${sharedStyle};
  font-size: 1.4427rem;
`;

const StyledH4 = styled.h4.attrs(({ style }) => ({ style }))`
  ${sharedStyle};
  font-size: 1rem;
`;

const StyledH5 = styled.h5.attrs(({ style }) => ({ style }))`
  ${sharedStyle};
  font-size: 0.83255rem;
`;

const StyledH6 = styled.h6.attrs(({ style }) => ({ style }))`
  ${sharedStyle};
  font-size: 0.75966rem;
  font-style: italic;
`;

export const H1 = ({ children, style = {}, className = '' }): React.ReactElement => (
  <StyledH1 style={style} className={className}>{children}</StyledH1>
);
export const H2 = ({ children, style = {}, className = '' }): React.ReactElement => (
  <StyledH2 style={style} className={className}>{children}</StyledH2>
);
export const H3 = ({ children, style = {}, className = '' }): React.ReactElement => (
  <StyledH3 style={style} className={className}>{children}</StyledH3>
);
export const H4 = ({ children, style = {}, className = '' }): React.ReactElement => (
  <StyledH4 style={style} className={className}>{children}</StyledH4>
);
export const H5 = ({ children, style = {}, className = '' }): React.ReactElement => (
  <StyledH5 style={style} className={className}>{children}</StyledH5>
);
export const H6 = ({ children, style = {}, className = '' }): React.ReactElement => (
  <StyledH6 style={style} className={className}>{children}</StyledH6>
);
